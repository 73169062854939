<template>
  <div>
    <v-card flat>
      <v-card-text class="px-0">
        <v-row dense>
          <v-col
            cols="12"
            md="3"
            lg="3"
            xl="3"
            :class="index == 0 ? 'mr-2 pl-0' : 'mr-2'"
            v-for="(buyCredit, index) of getBuyCredits"
            :key="index"
          >
            <v-card
              :class="isOutlined[index] ? 'card-border' : 'card-border-border'"
              @click="setOutline(index, buyCredit)"
              hover
            >
              <v-card-title>
                {{ $t(buyCredit.Title, { amount: buyCredit.Amount }) }}
              </v-card-title>
              <v-card-subtitle>
                {{
                  $t(buyCredit.Subtitle, {
                    amount: getDiscountByAmount(buyCredit),
                  })
                }}
              </v-card-subtitle>
              <v-card-text class="">
                <v-layout align-end fill-height class="mt-8">
                  <p class="font-weight-bold text-h4 primary--text mb-0">
                    {{ $t("Price ") + "€" + buyCredit.PriceAnHoure + $t("/h") }}
                  </p>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            ><v-card color="grey lighten-5" disabled>
              <v-card-title> {{ $t("Basic rate") }} </v-card-title>
              <v-card-subtitle>
                {{ $t("Base rate per hour") }}
              </v-card-subtitle>
              <v-card-text class="">
                <v-layout align-end fill-height class="mt-8">
                  <p class="font-weight-bold text-h4 primary--text mb-0">
                    {{ $t("Price ") + "€" + "82,5" + $t("/h") }}
                  </p>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card flat class="mt-4">
      <v-card-text class="px-0">
        <v-row no-gutters>
          <v-col cols="12" md="10">
            <p class="text-h5">{{ $t("Terms and Conditions") }}</p>
            <div
              class="
                text-justify
                pr-2
                mr-md-8 mr-lg-8
                overflow-auto
                terms-overscroll
              "
            >
              <p><b>Artikel 1. Toepassing algemene voorwaarden</b></p>
              <p>
                1.1. Deze algemene voorwaarden gelden op alle aanbiedingen van
                en overeenkomsten met Streamliners, ongeacht strijdige
                bepalingen vermeld op documenten van de klant. Door het plaatsen
                van een bestelling erkent de klant de algemene voorwaarden van
                Streamliners te aanvaarden.
              </p>
              <p><b>Artikel 2. Aanbiedingen, offertes & orderbevestiging</b></p>
              <p>
                2.1. Alle aanbiedingen en offertes van Streamliners zijn
                vrijblijvend tot op het ogenblik van de aanvaarding door de
                klant. Offertes blijven geldig tot 30 kalenderdagen na
                offertedatum tenzij anders vermeld.
              </p>
              <p>
                2.2 De overeenkomst komt tot stand wanneer de klant de offerte
                ongewijzigd binnen de acht dagen voor akkoord ondertekent en
                terugzendt aan Streamliners. Elke bestelling of orderbevestiging
                door de klant, verbindt de klant. De overeenkomst vervangt alle
                eerder afgesloten en/of mondelinge akkoorden.
              </p>
              <p>
                2.3 De offerte is niet deelbaar en kan bijgevolg niet
                opgesplitst worden tenzij anders vermeld. Een samengestelde
                prijsopgave verplicht Streamliners niet tot het verrichten van
                een gedeelte van de opdracht tegen een overeenkomstig deel van
                de opgegeven prijs.
              </p>
              <p>
                2.4 De bestelling wordt uitgevoerd vanaf ontvangst van het
                voorschot, tenzij nadrukkelijk anders vermeld in de offerte.
              </p>
              <p>
                2.5 Aanbiedingen en offertes gelden niet automatisch voor de
                toekomstige opdrachten.
              </p>
              <p><b>Artikel 3. Annulatie van de bestelling</b></p>
              <p>
                3.1. De annulatie van een bestelling door de klant is mogelijk
                zolang Streamliners haar werkzaamheden nog niet heeft aangevat
                en mits betaling van een schadevergoeding van 25% van de
                overeengekomen prijs, met een minimum van 500 EUR.
              </p>
              <p><b>Artikel 4. Levering</b></p>
              <p>
                4.1. De datum van levering wordt slechts als aanwijzing
                opgegeven en bindt Streamliners niet. Vertraging in de levering
                geeft de klant geen recht op schadevergoeding of
                prijsvermindering noch op ontbinding van de overeenkomst.
              </p>
              <p>
                4.2. Indien de partijen uitdrukkelijk een bindende
                leveringstermijn zijn overeengekomen, wordt deze termijn
                verlengd indien de klant in gebreke blijft om informatie,
                documenten, originelen, beelden (tijdig) over te maken en de
                verbeterde proeven (tijdig) te aanvaarden, of indien de klant
                bijkomende bestellingen plaatst.
              </p>
              <p><b>Artikel 5. Risico</b></p>
              <p>
                5.1. Alle goederen die toebehoren aan de klant en zich bij
                Streamliners bevinden, worden er bewaard op risico van de klant.
              </p>
              <p><b>Artikel 6. Betalingsmodaliteiten</b></p>
              <p>
                6.1. Behoudens uitdrukkelijke schriftelijke afwijking vermeld in
                de overeenkomst dient de klant bij elke bestelling een voorschot
                te betalen van 10% van het factuurbedrag. Het betaalde voorschot
                wordt in mindering gebracht op de daaropvolgende factuur. In
                geval een project over enkele maanden gespreid is wordt
                maandelijks een factuur opgemaakt met daarop de lijnen die
                uitgewerkt werden gedurende die maand. Het restbedrag wordt
                betaald na oplevering van het project.
              </p>
              <p>
                6.2. Alle facturen zijn betaalbaar op hun vervaldag door
                overschrijving op het rekeningnummer van Streamliners (vermeld
                op alle facturen en offertes).
              </p>
              <p>
                6.3. Indien de klant niet overgaat tot betaling binnen 8 dagen
                na ontvangst van een aanmaning hiertoe, is de klant aan
                Streamliners een nalatigheidsintrest verschuldigd van 12% per
                jaar en een forfaitaire schadevergoeding van 10% van het
                factuurbedrag met een minimum van 100,00 EUR, vanaf de datum van
                aanmaning tot aan de volledige betaling.
              </p>
              <p>
                6.4 Streamliners behoudt zich het recht voor om de verdere
                uitvoering van haar verbintenissen op te schorten totdat de
                klant de vervallen facturen heeft betaald. Elke vertraging in de
                betaling door de klant maakt alle verschuldigde sommen ineens
                opeisbaar. Tevens vervallen alle toegestane kortingen bij het
                niet respecteren van deze algemene verkoopsvoorwaarden.
              </p>
              <p><b>Artikel 7. Klachten – protest van de factuur</b></p>
              <p>
                7.1. Elk protest dient per gemotiveerde aangetekende brief aan
                Streamliners te worden bezorgd binnen een termijn van 8 dagen.
                Voor klachten of betwistingen met betrekking tot de geleverde
                diensten vangt de termijn aan daags na levering. Met betrekking
                tot de factuur begint de termijn op de factuurdatum. Bij gebreke
                aan tijdig protest zijn de diensten/facturen definitief aanvaard
                en is betaling verschuldigd.
              </p>
              <p><b>Artikel 8. Aansprakelijkheid – Algemeen</b></p>
              <p>
                8.1. Streamliners verbindt zich ertoe alle te verstrekken
                diensten met zorg uit te voeren. Alle prestaties van
                Streamliners zijn middelenverbintenissen. Streamliners is niet
                aansprakelijk voor fouten in de uitvoering te wijten aan
                onvoldoende of verkeerde input door de klant.
              </p>
              <p>
                8.2. Streamliners kan niet aansprakelijk worden gesteld voor
                enige fout (zelfs een grove fout) van haar of haar aangestelden,
                behoudens ingeval van bedrog. Streamliners zal, wat ook de
                oorzaak, de vorm of het voorwerp is van de vordering waarbij de
                aansprakelijkheid wordt ingeroepen, in geen geval aansprakelijk
                kunnen worden gesteld voor enige gevolgschade zoals bijvoorbeeld
                verlies van verwachte winst, daling van omzet, verhoogde
                operationele kosten, verlies van cliënteel, welke de klant of
                derden zouden lijden ten gevolge van enige fout of nalatigheid
                van Streamliners of een aangestelde.
              </p>
              <p>
                8.3. De aansprakelijkheid van Streamliners met betrekking tot
                aan de klant geleverde diensten is in elk geval beperkt tot
                ofwel de terugbetaling van de door de klant betaalde prijs,
                ofwel het opnieuw uitvoeren van de diensten, naar keuze van
                Streamliners. De totale aansprakelijkheid van Streamliners, zal
                nooit meer bedragen dan de prijs die door de klant aan
                Streamliners werd betaald voor de diensten die aanleiding gaven
                tot het schadegeval.
              </p>
              <p>
                8.4 Indien en voor zover een goede uitvoering van de
                overeenkomst dit vereist, heeft Streamliners het recht bepaalde
                werkzaamheden te laten verrichten door derden.
              </p>
              <p>
                8.5. Wat de diensten afkomstig van derde leveranciers betreft,
                aanvaardt Streamliners geen enkele aansprakelijkheid boven of
                anders dan de aansprakelijkheid die de derde leveranciers bereid
                zijn te aanvaarden voor hun producten of diensten.
              </p>
              <p>
                8.6 De opdrachtgever erkent onderling e-mail als wettelijk,
                geldig bewijsmiddel.
              </p>
              <p><b>Artikel 9. Aansprakelijkheid software</b></p>
              <p>
                9.1. Onverminderd artikel 8, geldt inzake software het volgende:
                de feilloze werking van een computerconfiguratie (het geheel van
                hardware en software) kan nooit volledig worden gegarandeerd,
                dit zowel wegens externe factoren (stroomuitval of -storing,
                blikseminslag,...) als wegens factoren eigen aan de
                computerconfiguratie (defecten, netwerkstoringen, onontdekte
                fouten in systeem- en toepassingssoftware,...), zodat o.m.
                onverwacht verlies van (zelfs alle) programma’s en/of gegevens
                kan optreden. De klant verbindt zich ertoe aangepaste
                mechanismen voor de beveiliging, de bewaring en de herstelling
                van gegevens te installeren.
              </p>
              <p><b>Artikel 10. Intellectuele eigendomsrechten</b></p>
              <p>
                10.1. Onder Intellectuele Eigendomsrechten wordt verstaan: alle
                intellectuele, industriële en andere eigendomsrechten (ongeacht
                of deze geregistreerd zijn of niet), met inbegrip van maar niet
                beperkt tot auteursrechten, naburige rechten, merken,
                handelsnamen, logo’s, tekeningen, modellen of aanvragen tot
                registratie als tekening of model, octrooien, aanvragen tot
                octrooien, domeinnamen, know-how, alsmede rechten op databanken,
                computer- programma’s en halfgeleiders.
              </p>
              <p>
                10.2. Beide partijen aanvaarden dat het concept van een website
                (m.n. de opbouw van de schermen van de website, hoofdnavigatie)
                in beginsel niet zal worden beschermd door Intellectuele
                Eigendomsrechten. De Klant kan dan ook een gelijkaardige opbouw
                terugvinden bij andere door Streamliners ontwikkelde sites en
                webapplicaties.
              </p>
              <p>
                10.3. De Intellectuele Eigendomsrechten verbonden aan het
                visuele ontwerp van de door Streamliners gecreëerde
                website/webapplicatie worden overgedragen aan de klant. Deze
                overdracht geldt in de meest volledige omvang, voor alle
                exploitatie-wijzen en -vormen, voor de gehele duur van het
                desbetreffende recht en voor de gehele wereld. Daarenboven
                krijgt de klant een niet-exclusieve gebruikslicentie op alle
                voor de website gebruikte codes. Deze gebruikslicentie geldt
                voor de duur van de bescherming van de code door het
                auteursrecht en voor de gehele wereld.<br />
                Indien de website evenwel foto’s of tekeningen bevat die niet
                door de klant werden aangeleverd, maar door Streamliners werden
                gehaald van een website die online foto’s en illustraties ter
                beschikking stelt al dan niet tegen betaling, dan is de
                gebruikslicentie die de klant op deze foto’s en tekeningen
                verkrijgt, afhankelijk van de voorwaarden bepaald op de website
                van deze online bibliotheek. In de regel zal deze
                gebruikslicentie nietexclusief zijn. Streamliners verstrekt geen
                enkele garantie met betrekking tot deze foto’s en illustraties.
              </p>
              <p>
                10.4. De Intellectuele Eigendomsrechten verbonden aan het CMS
                (d.i. de software nodig voor het beheer van de inhoud van de
                website) behoren exclusief toe aan Streamliners of een derde
                waarmee Streamliners hieromtrent een overeenkomst heeft
                gesloten. Het is de klant verboden om sublicenties aan derden
                toe te kennen, dan wel de software op enigerlei wijze aan derden
                ter beschikking te stellen, mee te delen, te gebruiken ten
                behoeve van derden of te commercialiseren.
              </p>
              <p>
                10.5. De klant zal de Intellectuele Eigendomsrechten van
                Streamliners ten allen tijde respecteren en redelijke
                inspanningen leveren om die rechten te beschermen. De klant zal
                Streamliners onmiddellijk in kennis stellen van iedere inbreuk
                door derden op de Intellectuele Eigendomsrechten van
                Streamliners waarvan hij kennis neemt.
              </p>
              <p><b>Artikel 11. Hostingdiensten & domeinnaam</b></p>
              <p>
                11.1. Voor de hosting en registratie van domeinnamen werkt
                Streamliners samen met een gespecialiseerde hostingpartner. Een
                beschrijving van de hosting diensten en de aansprakelijkheid van
                deze partner is opgenomen in de Service Level Agreement (SLA)
                van deze hostingpartner. Deze SLA kan worden aangepast of
                gewijzigd door de hostingpartner.
              </p>
              <p>
                11.2. De hostingdiensten worden door Streamliners aan de klant
                verstrekt per kalenderjaar, mits betaling door de klant van de
                verschuldigde vergoeding. De actuele prijslijst kan worden
                opgevraagd bij Streamliners en wordt jaarlijks aangepast. Indien
                de klant deze dienst wenst op te zeggen, dient hij dit te doen
                door uiterlijk 1 maand voor de ingang van de jaarlijkse termijn,
                zijn opzeg aan Streamliners over te maken per aangetekende brief
                of 2 maand voor de ingang van de nieuwe termijn per e-mail
                waarop akkoord verkregen door antwoord van Streamliners. Bij
                laattijdige opzeg zal de klant de vergoeding voor het volgende
                kalenderjaar verschuldigd zijn.
              </p>
              <p>
                11.3 Streamliners is nimmer aansprakelijk voor de door de
                gebruiker op haar systemen geplaatste inhoud.
              </p>
              <p>
                11.4 Tenzij er terzake een specifieke bijkomende en afwijkende
                overeenkomst met Streamliners wordt afgesloten over de
                terbeschikkingstelling door Streamliners van backups, is de
                gebruiker uitsluitend verantwoordelijk voor het nemen van
                backups van zijn hosting-account. Streamliners kan hiervoor op
                geen enkele wijze aansprakelijk worden gesteld. Voor zover er
                door Streamliners eventueel backups zouden worden genomen, zijn
                deze enkel bestemd voor intern gebruik.
              </p>
              <p><b>Artikel 12. Aanlevering bronbestanden</b></p>
              <p>
                12.1. Bronbestanden, gebruikt voor de creatie van het product
                worden niet aangeleverd tenzij anders vermeld in de
                overeenkomst. Bronbestanden kunnen echter steeds tegen betaling
                verkregen worden.
              </p>
              <p><b>Artikel 13. Beëindiging van de overeenkomst</b></p>
              <p>
                13.1. Indien de klant zich schuldig maakt aan een zware
                contractuele wanprestatie die de klant niet herstelt binnen de 8
                dagen na ontvangst van een ter post aangetekende
                ingebrekestelling, heeft Streamliners het recht om ofwel (1) de
                overeenkomst te schorsen totdat de klant zijn verbintenissen is
                nagekomen, ofwel (2) de overeenkomst met onmiddellijke ingang te
                beëindigen. De niet-betaling van één of meerdere facturen op hun
                vervaldag, zal altijd worden beschouwd als een zware
                contractuele wanprestatie.
              </p>
              <p>
                13.2. Bij beëindiging van de overeenkomst zal de klant alle door
                Streamliners verleende diensten betalen, alsook de kosten die
                Streamliners moet maken als gevolg van deze beëindiging,
                vermeerderd met een forfaitaire schadevergoeding van 30% van het
                bedrag dat Streamliners nog had kunnen factureren aan de klant
                indien de overeenkomst volledig zou zijn uitgevoerd. Het
                eventueel betaald voorschot blijft hoe dan ook verworven voor
                Streamliners. Bovendien behoudt Streamliners het recht om een
                hogere schadevergoeding te vorderen indien zij bewijst dat haar
                werkelijk geleden schade groter is dan de forfaitaire schade
                zoals hierboven bepaald.
              </p>
              <p>
                13.3. Niettemin aanvaardt elke partij om aan de andere partij
                een redelijke termijn toe te kennen om haar eventuele
                tekortkomingen te verhelpen, en om steeds eerst te zoeken naar
                een minnelijke regeling.
              </p>
              <p><b>Artikel 14. Geheimhoudingsplicht</b></p>
              <p>
                14.1. Partijen verbinden zich ertoe de commerciële en technische
                informatie en de bedrijfsgeheimen die zij vernemen van de andere
                partij, zelfs na de beëindiging van de overeenkomst, geheim te
                houden en enkel te gebruiken voor de uitvoering van de
                overeenkomst.
              </p>
              <p><b>Artikel 15. Verwerking persoonsgegevens</b></p>
              <p>
                15.1. Voor zover de klant persoonsgegevens verwerkt op de server
                van Streamliners, heeft Streamliners de hoedanigheid van
                verwerker. De klant heeft de hoedanigheid van verantwoordelijke
                voor de verwerking van persoonsgegevens in de zin van de Wet
                Verwerking Persoonsgegevens. De klant verklaart de
                verplichtingen die rusten op de verantwoordelijke voor de
                verwerking, opgenomen in deze wet, integraal na te leven.
              </p>
              <p>
                15.2. In het kader van de diensten voor de klant, verwerkt
                Streamliners persoonsgegevens van de door de klant opgegeven
                contactpersonen. De contactgegevens van deze personen worden
                verwerkt voor het doeleinde ‘klantenbeheer’, i.e. om met de
                klant in contact te treden m.b.t. de diensten.
              </p>
              <p>
                15.3 De klant beschikt over het recht op inzage en eventuele
                correctie van zijn of haar persoonsgegevens mits bewijs van
                identiteit (bv. aan de hand van een kopie van de
                identiteitskaart). Aanvragen dienen schriftelijk, gedateerd en
                ondertekend aan Streamliners bezorgt te worden. Dit kan via
                info@streamliners.be of via het postadres. <br />
                Streamliners stelt alles in het werk om de gegevens zo spoedig
                mogelijk bij te werken. In geval van vragen of klachten kan er
                steeds contact opgenomen worden via 051 14 02 15 of
                info@streamliners. be
              </p>
              <p><b>Artikel 16. Referentie</b></p>
              <p>
                16.1. De klant gaat ermee akkoord dat het door Streamliners voor
                de klant ontwikkelde product wordt opgenomen in het
                referentieportfolio van Streamliners.
              </p>
              <p><b>Artikel 17. Overmacht</b></p>
              <p>
                17.1. Overmachtsituaties zoals bijvoorbeeld stakingen, publieke
                onrust, administratieve maatregelen en andere onverwachte
                gebeurtenissen waarover Streamliners geen controle heeft,
                bevrijden Streamliners, voor de duur van de hinder en voor hun
                draagwijdte, van haar verbintenissen, zonder recht op enige
                prijsvermindering of schadevergoeding voor de klant.
              </p>
              <p>
                17.2 Wanneer in bovenstaande situatie geconcludeerd wordt dat
                het niet meer mogelijk is de verbintenissen redelijkerwijs na te
                komen, zal de overeenkomst in onderling overleg herzien of
                ontbonden worden. Eventuele reeds geleverde prestaties door
                Streamliners tot aan het moment van overmacht zullen alsnog
                gefactureerd worden.
              </p>
              <p><b>Artikel 18. Nietigheid</b></p>
              <p>
                18.1. Indien enige bepaling van deze algemene voorwaarden nietig
                is, zullen de overige bepalingen volledig van kracht blijven en
                zullen Streamliners en de klant de nietige bepaling vervangen
                door een andere bepaling die het doel en de strekking van de
                nietige bepaling zoveel mogelijk benadert.
              </p>
              <p><b>Artikel 19. Toepasselijk recht – bevoegde rechtbank</b></p>
              <p>
                19.1. Het Belgisch recht is van toepassing op de overeenkomsten
                van Streamliners. Elk geschil met betrekking tot het sluiten, de
                geldigheid, de uitvoering en/of de beëindiging van deze
                overeenkomst zal worden beslecht door een bevoegde rechtbank.
              </p>
              <p><b>Artikel 20. Privacy</b></p>
              <p>
                Alle gegevens worden met de nodige zorg en discretie door
                Streamliners behandeld zoals opgenomen in artikel 15. Meer info
                over de privacy richtlijnen is terug te vinden op de privacy
                statement pagina.
              </p>
              <p>Algemene voorwaarden Streamliners bv - v1.0 - 10/02/2020</p>
            </div>
          </v-col>
          <v-col cols="12" md="2">
            <p class="text-h5">{{ $t("Summary") }}</p>
            <v-layout column>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-layout justify-space-between>
                    <p class="mb-1">{{ $t("Credits") }}</p>
                    <p class="mb-1">{{ getAmount }}</p>
                  </v-layout>
                </v-col>
                <v-col cols="12">
                  <v-layout justify-space-between>
                    <p class="mb-1">{{ $t("Base rate") }}</p>
                    <p class="mb-1">€82,5 {{ $t("excl.") }}</p>
                  </v-layout>
                </v-col>
                <v-col cols="12">
                  <v-layout justify-space-between>
                    <p class="mb-1">{{ $t("Total discount") }}</p>
                    <p class="mb-1 font-weight-bold">
                      {{ "€" + getTotalDiscount }} {{ $t("excl.") }}
                    </p>
                  </v-layout>
                </v-col>
                <v-col cols="12">
                  <v-divider class="my-2"></v-divider>
                  <v-layout justify-space-between>
                    <p class="mb-1 font-weight-bold">{{ $t("Total") }}</p>
                    <p class="mb-1 font-weight-bold">
                      {{ "€" + getTotalPrice }} {{ $t("excl.") }}
                    </p>
                  </v-layout>
                </v-col>
              </v-row>
              <v-layout column justify-end>
                <v-checkbox
                  v-model="terms"
                  :label="$t('I agree to the Terms and Conditions')"
                ></v-checkbox>
                <v-btn
                  :disabled="checkPurchase"
                  color="primary"
                  @click="confirmPurchase()"
                >
                  {{ $t("Confirm order") }}
                </v-btn>
              </v-layout>
            </v-layout>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <errorHandeling
      v-if="error"
      :snackbarText="error"
      snackbarColor="error"
      snackbarTimout="-1"
      snackbarIcon="mdi-check-circle"
      @clearError="$store.dispatch('resetError')"
    />
    <v-dialog v-model="confirmPurchaseDialog" width="600">
      <v-card>
        <v-card-title> {{ $t("Thanks for your order.") }} </v-card-title>

        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12">
              <v-layout align-center fill-height>
                {{ $t("The credits will be added to your account.") }}
              </v-layout>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="confirmPurchaseDialog = false">
            {{ $t("Close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import request from "../../request";
export default {
  data() {
    return {
      terms: false,
      isOutlined: [],
      buyCredits: [
        {
          Amount: "24",
          Title: "Order credits amount",
          Subtitle: "Base rate per hour. With a total discount of", // + " €168 ",
          PriceAnHoure: "75",
          isBasePrice: false,
        },
        {
          Amount: "12",
          Title: "Order credits amount",
          Subtitle: "Base rate per hour. With a total discount of", //+ " €60 ",
          PriceAnHoure: "77.5",
          isBasePrice: false,
        },
        {
          Amount: "6",
          Title: "Order credits amount",
          Subtitle: "Base rate per hour. With a total discount of", //+ " €15 ",
          PriceAnHoure: "80",
          isBasePrice: false,
        },
      ],
      confirmPurchaseDialog: false,
      dialogCredits: null,
      error: "",
    };
  },
  created() {
    this.dialogCredits = this.buyCredits[0];
    this.isOutlined[0] = true;
  },
  computed: {
    getBuyCredits() {
      return this.buyCredits;
    },
    checkPurchase() {
      return !(this.terms && this.dialogCredits);
    },
    getTotalDiscount() {
      if (!this.dialogCredits) return 0;
      const totalPriceCredits =
        Number(this.dialogCredits.PriceAnHoure) * this.getAmount;
      const totalPriceCreditsBaseRate = 82.5 * this.getAmount;
      return totalPriceCreditsBaseRate - totalPriceCredits;
    },
    getTotalPrice() {
      if (!this.dialogCredits) return 0;
      return Number(this.dialogCredits.PriceAnHoure) * this.getAmount;
    },
    getAmount() {
      if (!this.dialogCredits) return 0;
      return this.dialogCredits.Amount;
    },
  },
  methods: {
    getDiscountByAmount(item) {
      const totalPriceCredits = Number(item.PriceAnHoure) * item.Amount;
      const totalPriceCreditsBaseRate = 82.5 * item.Amount;
      return totalPriceCreditsBaseRate - totalPriceCredits;
    },
    setOutline(i, item) {
      this.dialogCredits = item;

      for (const key in this.isOutlined) {
        if (this.isOutlined[key]) {
          this.$set(this.isOutlined, key, false);
        }
      }

      this.$set(this.isOutlined, i, true);
    },
    confirmPurchase() {
      request.post(
        "/api/order-credits",
        {
          Agreed: this.terms,
          Amount: this.dialogCredits.Amount
        },
        (res) => {
          if (res.success) {
            this.confirmPurchaseDialog = true;
            this.terms = false;
          } else {
            this.error = res.message;
          }
        }
      );
    },
  },
};
</script>

<style scoped>
.terms-overscroll {
  height: 35vh;
}
.card-border-border {
  background-color: #ffffff !important;
  border-left: 5px solid white;
}
.card-border {
  background-color: #ffffff !important;
  border-left: 5px solid !important;
}
</style>